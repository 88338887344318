<template>
  <vx-card class="welcome">
    <div class="vx-col w-full mb-base">
      <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--          <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">-->
          <h1 class="sm:mx-0 mx-4 text-5xl d-theme-heading-color" style="margin-top: 12rem">ยินดีต้อนรับเข้าสู่<br/>โปรแกรมระบบประปา</h1>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
  import moduleInvoiceDate from "@/store/invoice-date/moduleInvoiceDate.js";
  import moment from "moment";
  import * as lang from "vuejs-datepicker/dist/locale";

  export default {
    components: {
    },
    data() {
      return {
        currentDate: new Date(),
      }
    },
    computed: {
      invoiceData() {
        return this.$store.state.invoiceDate.items;
      }
    },
    methods: {
      formatToThaiDate(val, format) {
        'use strict'
        // const date = moment(text).format('DD/MM/YYYY');
        // const dateSplit = date.split('/');
        // const year = parseInt(dateSplit[2]) + 543;
        // return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
        const date = moment(val).locale("th");
        date.add(543, "years");
        return date.format(format);
      },
      goToSetPaymentDialog(date) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'warning',
          title: 'คำเตือน',
          text: `ท่านยังไม่ได้กำหนดรอบส่งเงิน (ปัจจุบันรอบส่งเงินคือวันที่ ${date}) หากท่านไม่กำหนดรอบจะไม่สามารถรับชำระเงินได้`,
          acceptText: 'กำหนดรอบส่งเงิน',
          cancelText: 'ยังก่อน',
          accept: this.goToSetPayment
        })
      },
      goToSetPayment() {
        this.$router.push('/operation/set_payment')
      },
    },
    async created() {
      this.$vs.loading();
      if (!moduleInvoiceDate.isRegistered) {
        this.$store.registerModule('invoiceDate', moduleInvoiceDate)
        moduleInvoiceDate.isRegistered = true
      }
      await this.$store.dispatch("invoiceDate/fetchCurrentDataListItems").then((res) => {
        if (res.status === 200 && res.data) {
          const currentInvoiceDate = moment(res.data.invoice_date).format('YYYY-MM-DD')
          const currentDate = moment().format('YYYY-MM-DD')
          if(currentInvoiceDate < currentDate){
            this.goToSetPaymentDialog(this.formatToThaiDate(currentInvoiceDate, 'D MMMM YYYY'))
          }
        } else {
          this.$vs.loading.close();
        }
      }).catch((error) => {
        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    }
  }
</script>
<style>
  .welcome {
    background-image: url("../assets/images/pages/background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
